<template>
  <button :class='addClass' :disabled='disabled||loading' @click='$emit("emitClick")'>
    <slot v-if='!loading' />
    <vue-loading
      v-if='loading'
      :type='loadIcon.type'
      :size='loadIcon.size'
      :color='loadIcon.color'
    />
  </button>
</template>

<script>
import { VueLoading } from 'vue-loading-template';

export default {
  props: {
    white: Boolean,
    loading: Boolean,
    disabled: Boolean,
  },
  components: {
    VueLoading,
  },
  data() {
    return {
      loadIcon: {
        type: 'spin',
        size: { width: '14px', height: '14px' },
        color: '#000',
      },
    };
  },
  computed: {
    addClass() {
      return {
        'button-normal': true,
        'button-normal--white': this.white,
        'button-normal--disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.button-normal {
  background-color: $color-yellow;
  border: 1px solid $color-dark-yellow;
  border-radius: 3px;
  padding: 2px 8px;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  min-width: 80px;
  cursor: pointer;
  transition: 0.1s;
  color: $color-black;
  a {
    text-decoration: none;
    color: $color-black;
    padding: 8px 10px;
    width: 100%;
  }
  a:visited {
    text-decoration: none;
    color: $color-black;
  }
  p {
    padding: 0 10px;
  }
}

.button-normal:hover {
  background-color: $color-dark-yellow;
}

.button-normal--white {
  background-color: $color-white;
  border: 1.5px solid $color-dark-yellow;
}

.button-normal--white:hover {
  background-color: $color-light-gray;
}

.button-normal--disabled {
  background-color: $color-gray;
  border: 1.5px solid $color-gray;
  color: $color-dark-gray;
}

.button-normal--disabled:hover {
  background-color: $color-gray;
  border: 1.5px solid $color-gray;
  color: $color-dark-gray;
}
</style>
